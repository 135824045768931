import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkOrderPackagingComponent } from './work-order-packaging.component';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import {InputTextModule} from "primeng/inputtext";
import { DisableArrowKeysModule } from '@ctsolution/ct-framework';
import { DropdownModule } from 'primeng/dropdown';


@NgModule({
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    DisableArrowKeysModule,
    DropdownModule
  ],
  declarations: [WorkOrderPackagingComponent],
  exports : [WorkOrderPackagingComponent]
})
export class WorkOrderPackagingModule { }
