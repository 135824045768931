<app-company-edit-form (onSubmit)="submit($event)" [isSupplier]="isSupplier" [isDialog]="dialogData"
  [datatableContainerFilterValues]="datatableContainerFilterValues"></app-company-edit-form>

<ng-container *ngIf="enableMasterDetail">

  <div class="ct-manager-master-detail-container">

    <app-company-information [masterConfiguration]="companyModelConfiguration"></app-company-information>

  </div>

  <div class="ct-manager-master-detail-container">

    <app-company-place-list [masterConfiguration]="companyModelConfiguration"></app-company-place-list>

  </div>

  <!-- <div class="ct-manager-master-detail-container" *ngIf="!isSupplier">

    <app-company-lead [companyModelConfiguration]="companyModelConfiguration"></app-company-lead>

  </div> -->

  <div class="ct-manager-master-detail-container">

    <app-company-contract [masterConfiguration]="companyModelConfiguration"></app-company-contract>

  </div>

  <div class="ct-manager-master-detail-container">

    <app-company-contact *ngIf="companyContactModelConfiguration" [configuration]="companyContactModelConfiguration"
      [title]="'company.references'"></app-company-contact>

  </div>

</ng-container>
