import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulerListComponent } from './scheduler-list.component';
import { CtModelModule } from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule
  ],
  declarations: [SchedulerListComponent],
  exports: [SchedulerListComponent]
})
export class SchedulerListModule { }
