<div class="create-packaging-dialog-cntr">
  <ng-container *ngIf="step1">
    <h1 mat-dialog-title>Stai per creare il tuo packaging</h1>
    <h4 mat-dialog-subtitle>Seleziona gli articoli che preferisci</h4>
    <div mat-dialog-content>
      <p-table [value]="items" [(selection)]="selectedItems" dataKey="code">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
            <th>Codice</th>
            <th>Nome</th>
            <th>Prezzo</th>
            <th>Quantità</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>
              <p-tableCheckbox [value]="item" />
            </td>
            <td>{{ item.code }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.quantity }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div mat-dialog-actions class="button-cntr">
      <p-button severity="danger" (click)="onNoClick()">Chiudi</p-button>
      <p-button
        [disabled]="selectedItems.length < 1"
        pRipple
        (click)="submit(1)"
        >Avanti</p-button
      >
    </div>
  </ng-container>

  <ng-container *ngIf="step2">
    <div class="item-cntr">
      <strong>Articolo </strong>
      <strong>Seleziona quantità </strong>
    </div>
    <ng-container *ngFor="let item of selectedItems">
      <div class="item-cntr">
        {{ item.code }} - {{ item.description }}
        <input
          style="width: 70px"
          pInputText
          pKeyFilter="int"
          placeholder=""
          [(ngModel)]="value"
          [value]="item.quantity"
        />
      </div>
    </ng-container>

    <div mat-dialog-actions class="button-cntr">
      <p-button severity="danger" (click)="onNoClick()">Chiudi</p-button>
      <p-button pRipple (click)="submit(2)">Avanti</p-button>
    </div>
  </ng-container>

  <ng-container *ngIf="step3">
    <h4 mat-dialog-subtitle>
      Seleziona il packaging a cui vuoi inserire gli articoli o creane uno
      nuovo.
    </h4>

    <div class="item-cntr">
      <p-dropdown
        style="width: 70%"
        [options]="packagies"
        [(ngModel)]="selectedPack"
        optionLabel="name"
      ></p-dropdown>
      <p-button label="Crea" icon="pi pi-plus" iconPos="right" (click)="navigateToPackaging()"></p-button>
    </div>

    <div mat-dialog-actions class="button-cntr">
      <p-button severity="danger" (click)="onNoClick()">Chiudi</p-button>
      <p-button pRipple (click)="submit(3)">Salva</p-button>
    </div>
  </ng-container>

  <ng-container *ngIf="step4">
    <h4 mat-dialog-subtitle>
      Hai salvato corretamente questi articoli nel packaging selezionato:
    </h4>

    <ng-container *ngFor="let item of selectedItems">
      <div class="item-cntr">
        <strong>{{ item.code }} - {{ item.description }}</strong>
      </div>
    </ng-container>

    <div mat-dialog-actions class="button-cntr">
      <p-button
        style="justify-content: end"
        severity="danger"
        (click)="onNoClick()"
        >Concludi</p-button
      >
    </div>
  </ng-container>
</div>
<!-- Qua mi aspetto titolo e descrizione esplicativa, poi un elenco di item e la quantità selezionabile presi da una commessa che creerai tu chiamata DEMO PACKAGING cosi marghe utilizzerà quella in call. -->

<!-- l'elenco di item dovrà essere in qualche modo selezionabile dal cliente, con delle checkbox o del testo evidenziato sul click o come preferisci -->

<!-- Quando il cliente farà submit e passa alla modale successiva l'elenco selezionato te lo dovrai trasportare -->

<!-- step 2: di nuovo titolo e descrizione esplicativa per far capire che si può selezionare un packaging già esistente o crearne uno nuovo, mostrare un elenco con una select di packaging "finti" e inserire un bottone di creazione packaging.
  step 3: alla submit finale "salva/concludi" uscirà un testo con scritto il tuo packaging è stato creato correttamente con questi item e queste quantità. -->
