import { Component, OnInit } from '@angular/core';
import { CtModelConfiguration, CtSchedulerConfiguration } from '@ctsolution/ct-framework';
import { SchedulerRouteData } from '../../_core/route-data/schedule.route-data';
import { SchedulerService } from './scheduler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventTypeEnum, SchedulerEventPath } from './scheduler-event-button/scheduler-event-button.component';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit {


  schedulerConfiguration : CtSchedulerConfiguration = CtSchedulerConfiguration
  .create()
  .setLocale('it')
  .setOnCreate(async () => this.onCreate())

  configuration : CtModelConfiguration<any> = CtModelConfiguration.create()
  .setRouteData(SchedulerRouteData())
  .setCTSchedulerConfiguration(this.schedulerConfiguration)

  constructor(private schedulerService: SchedulerService,private route : ActivatedRoute,private router : Router) { }

  ngOnInit() {
  }

  async onCreate(){

    const type = this.route.snapshot.queryParamMap.get('TypeExtra');
    const oid = this.route.snapshot.queryParamMap.get('OidExtra');
    let param : SchedulerEventPath | null = null;
    if(type && oid)
        param = SchedulerEventPath.create(+oid,+type);

    const ret = await this.schedulerService.createEventFromPath(this.configuration,param);

    if(ret && param && type)
    {
      this.router.navigate([+type === EventTypeEnum.WORKORDER ? 'work-order' : 'lead','edit',oid]) //TODO: Rivedere quando ci saranno piu tipi
      return
    }

    if(ret)
      window.location.reload();
  }



}
