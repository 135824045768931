import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { Router } from '@angular/router';
@Component({
  selector: 'app-work-order-packaging',
  templateUrl: './work-order-packaging.component.html',
  styleUrls: ['./work-order-packaging.component.scss']
})
export class WorkOrderPackagingComponent {
  items = [
    { code: 'FER001', description: 'BOLT M10x80', quantity: 10, amount: 10.00, discount: '-', vat: '-' },
    { code: 'FER004', description: 'WASHER M14', quantity: 150, amount: 22.00, discount: '-', vat: '-' },
    { code: 'FER006', description: 'WASHER M10x30', quantity: 22, amount: 11.00, discount: '-', vat: '-' },
    { code: 'FER013', description: 'WASHER M16', quantity: 122, amount: 2.00, discount: '-', vat: '-' },
    { code: 'GF002', description: 'POLE GATE-C', quantity: 133, amount: 34.00, discount: '-', vat: '-' }
  ];

  packagies = [
    { name: 'Packaging test', code: 'TT' },
    { name: 'Packaging Marghe', code: 'RR' },
    { name: 'Scatola magazzino', code: 'LL' }
];
selectedPack: any;

  selectedItems = [];
  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;

  constructor(
    public dialogRef: MatDialogRef<WorkOrderPackagingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router : Router
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(num : number){
   
    if(this.selectedItems.length < 1) return;

    switch (num){

    case 1:
      this.step1 = false;
      this.step2 = true;
      break;

    case 2:
      this.step2 = false;
      this.step3 = true;
      break;

      case 3:
        this.step3 = false;
        this.step4 = true;
        break;

    default:
      break;
    }

  }

  navigateToPackaging(){

    this.router.navigate(['/location/box/create']);

    this.dialogRef.close();

  }
}
