import { Component, OnInit } from '@angular/core';
import { SchedulerService } from '../scheduler.service';
import { CtButtonConfiguration, MAT_RAISED_PRIMARY } from '@ctsolution/ct-framework';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'scheduler-event-button',
  templateUrl: './scheduler-event-button.component.html',
  styleUrls: ['./scheduler-event-button.component.scss']
})
export class SchedulerEventButtonComponent implements OnInit {

  constructor(private schedulerService: SchedulerService, private router : Router, private route : ActivatedRoute) { }

  configuration : CtButtonConfiguration =
  CtButtonConfiguration.create()
  .setAction(() => this.createEventFromPath())
  .setLabel('schedulerEventButton')
  .setMatherialOptions(MAT_RAISED_PRIMARY)

  ngOnInit() {
  }

  createEventFromPath(){

    let pId = this.route.snapshot.paramMap.get("id");

    const currentUrl = this.router.url;
    let type : EventTypeEnum | null = null;

    if (currentUrl.includes('lead')) {
      type = EventTypeEnum.LEAD;
    } else if (currentUrl.includes('work-order')) {
      type = EventTypeEnum.WORKORDER;
    }

    if(!pId) return;
    const param : SchedulerEventPath = SchedulerEventPath.create(+pId,type);

    this.schedulerService.navigateToPath(param)
  }

}

export class SchedulerEventPath {

  constructor(public Oid : number | null, public Type : EventTypeEnum | null) { }

  public static create = (oid : number | null, type : EventTypeEnum | null): SchedulerEventPath => new SchedulerEventPath(oid, type);

}

export enum EventTypeEnum {

  NotSet = 0,
  LEAD = 1,
  WORKORDER = 2,
  ORDER = 3,
  SCHEDULER = 4
}
